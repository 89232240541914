import * as React from 'react';
import { isArray } from '../helpers/javascript';
import { cleanupUserData, getGoogleSheetToken } from '../services/security.service';
import { manageCart } from '../services/stripe.services';
import { useAuth0 } from '@auth0/auth0-react';
import { gtmFireEvent } from '~/helpers/gtm';
import getWoxoLocationFromPath from './helpers/signUpHelper';
import { useTeamStore } from '~/store/team-store/use-team.store';
import { unstable_batchedUpdates } from 'react-dom';

type AuthState = Omit<AuthContextValue, 'dispatch'>;
const initialState: AuthState = {
  notifications: { data: [], count: 0 }
};

type ActionType =
  | { type: 'UPDATE_SHEET_TOKEN' }
  | { type: 'CLEAR_NOTIFICATIONS' }
  | { type: 'PUSH_NOTIFICATIONS'; notifications: Notifications }
  | { type: 'REMOVE_NOTIFICATIONS'; notification: string }
  | { type: 'CHANGE_TEAM'; team: { id: string; brandings: any[] } }
  | { type: 'CHANGE_BRANDING'; branding: Object };

const authReducer = (state: AuthState, action: ActionType) => {
  switch (action.type) {
    case 'UPDATE_SHEET_TOKEN':
      return { ...state, token: getGoogleSheetToken() };
    case 'CLEAR_NOTIFICATIONS': {
      return { ...state, notifications: { data: [], count: 0 } };
    }
    case 'PUSH_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          data:
            state.notifications && isArray(state.notifications.data)
              ? state.notifications.data.concat(action.notifications.data)
              : action.notifications.data,
          count: action.notifications.count
        }
      };
    case 'REMOVE_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          data:
            state.notifications && isArray(state.notifications.data)
              ? state.notifications.data.filter((n) => n._id !== action.notification)
              : [],
          count: state.notifications.count === 0 ? 0 : state.notifications.count - 1
        }
      };
    case 'CHANGE_TEAM': {
      unstable_batchedUpdates(() => useTeamStore.getState().setTeam(action.team));
      return state;
    }
    case 'CHANGE_BRANDING': {
      unstable_batchedUpdates(() => useTeamStore.getState().setBranding(action.branding));
      return state;
    }
    default:
      throw new Error('Inexistent action');
  }
};

interface Notification {
  _id: string;
}

interface Notifications {
  data: Notification[];
  count: number;
}

interface AuthContextValue {
  notifications: Notifications;
  dispatch: React.Dispatch<ActionType>;
}

const AuthContext = React.createContext(initialState);

export function AuthProvider({
  children,
  stripeSuccess
}: React.PropsWithChildren<{ stripeSuccess?: string }>) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  const { isAuthenticated } = useAuth0();

  const value = React.useMemo(
    () => ({
      notifications: state.notifications,
      dispatch
    }),
    [state.notifications]
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      if (stripeSuccess) {
        manageCart({ status: stripeSuccess === 'false' ? 'active' : 'inactive' });
      }
    }
  }, [isAuthenticated, stripeSuccess]);

  // React.useEffect(() => {
  //   const onFocus = () => {
  //     if (!logged()) {
  //       dispatch({ type: 'LOGOUT' });
  //     } /*else {
  //       dispatch({ type: 'LOGIN' });
  //     }*/
  //   };
  //   window.addEventListener('focus', onFocus);

  //   return () => window.removeEventListener('focus', onFocus);
  // }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthentication() {
  const settings = React.useContext(AuthContext as unknown as React.Context<AuthContextValue>);
  const auth0 = useAuth0();
  const user = React.useMemo(() => {
    const user = auth0.user;
    if (user) {
      return {
        ...user,
        _id: user.sub?.split('|')[1],
        username:
          user.username || user['https://woxo.tech/username'] || user.nickname?.replaceAll(' ', '_')
      };
    }
    return user;
  }, [auth0.user]);
  const logout = auth0.logout;
  const loginWithPopup = auth0.loginWithPopup;
  const loginWithRedirect = auth0.loginWithRedirect;

  return {
    ...settings,
    ...auth0,
    user,
    showLoginWithRedirect: React.useCallback(
      (
        returnTo: string | null = null,
        screen_hint: string = 'login',
        user_audience: string = 'video'
      ) => {
        const stage = screen_hint === 'signup' ? getWoxoLocationFromPath() : null;
        if (window) localStorage.setItem('lastReferrer', window.location.pathname);
        loginWithRedirect({
          screen_hint,
          prompt: 'login',
          redirectUri: `${window.location.origin}/processing/${screen_hint}`,
          appState: { returnTo },
          user_audience,
          stage
        });
      },
      [loginWithRedirect]
    ),
    showLogin: React.useCallback(() => {
      loginWithPopup();
    }, [loginWithPopup]),
    showSignUp: React.useCallback(
      // ADD: default value for callback
      (callback: () => void = () => {}) => {
        loginWithPopup({ screen_hint: 'signup' }).then(() => {
          callback();
        });
      },
      [loginWithPopup]
    ),
    logout: React.useCallback(
      (returnTo?: string) => {
        cleanupUserData();

        gtmFireEvent({
          event: 'onSecurity',
          category: 'security',
          action: 'logout',
          label: 'user logout action'
        });

        gtmFireEvent({
          event: 'userLogout'
        });

        logout({ returnTo: returnTo || window.location.origin });
      },
      [logout]
    )
  };
}
