const presets = [
  {
    name: 'Basic',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Solid',
    style: {
      fontFamily: '@font',
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      padding: 50,
      styles: [
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize}}'
          }
        },
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize2}}'
          }
        }
      ]
    }
  },
  {
    name: 'Step Up',
    style: {
      fontSize: 85,
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: 'transparent',
      fill: '{{TextColor}}',
      textAlign: 'left',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' },
      styles: [
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize}}',
            fontWeight: 500,
            textBackgroundColor: '{{BackgroundColor}}',
            textAlign: 'left'
          }
        },
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize2}}',
            stroke: '{{TextColor}}',
            strokeWidth: 2,
            textAlign: 'left'
          }
        }
      ]
    }
  },
  {
    name: 'Step Center',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: 'transparent',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' },
      styles: [
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize}}',
            fontWeight: 500,
            stroke: '{{TextColor}}',
            strokeWidth: 2,
            textAlign: 'left'
          }
        },
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize2}}',
            textBackgroundColor: '{{BackgroundColor}}',
            textAlign: 'left'
          }
        }
      ]
    }
  },
  {
    name: 'Criss Cross',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: 'transparent',
      fill: '{{TextColor}}',
      styles: [
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize}}',
            fontWeight: 500,
            fill: '{{TextColor}}',
            textBackgroundColor: '{{BackgroundColor}}',
            textAlign: 'left'
          }
        },
        {
          type: 'line',
          style: {
            fontSize: '{{FontSize2}}',
            fill: '{{BackgroundColor}}',
            textBackgroundColor: '{{TextColor}}',
            textAlign: 'left'
          }
        }
      ]
    }
  },
  {
    name: 'Roll Out',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Under Line',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Center Line',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Line Up',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Boundary',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Simple and Clean',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Honor',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}',
      stroke: '{{BackgroundColor}}',
      strokeWidth: '{{TextStroke}}',
      shadow: { color: '{{BackgroundColor}}', blur: '{{TextShadow}}' }
    }
  },
  {
    name: 'Pop Out',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}'
    }
  },
  {
    name: 'Trade Mark',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}'
    }
  },
  {
    name: 'Brush Stroke',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}'
    }
  },
  {
    name: 'Dream Meme',
    style: {
      fontSize: '{{FontSize}}',
      fontFamily: '@font',
      fontWeight: 500,
      backgroundColor: '{{BackgroundColor}}',
      fill: '{{TextColor}}'
    }
  }
];

export default presets;
