import { Typography, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Router from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isFunction } from '../../helpers/javascript';
import Dialog from '../Dialog';
import { PlansSwitch } from './PlansSwitch';
import { WidgetsPlanHeader } from './WidgetsPlanHeader';
import { VideosPlanHeader } from './VideosPlanHeader';
import { PlansToggle } from './PlansToggle';
import { PlanCard, PlanCardStatus } from './PlanCard';
import useRegisterPlans from '../../hooks/useRegisterPlans';
import { usePlan } from '~/services/stripe.services';
import { useAuthentication } from '~/hooks/useAuthentication';
import { gtmFireEvent } from '~/helpers/gtm';
import getWoxoLocationFromPath from '~/hooks/helpers/signUpHelper';
import { PriceSwitch } from '../PricingTabs/PriceSwitch';
import { HeaderPlans, PlanDiscountInfo, Wrapper } from './components/styled.components';
import { PlansTabs } from './PlansTabs';
import { BannerContext } from '~/components/Banner';

function roundPrice(price: number) {
  return (Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2);
}

function PlanCards(props: PlanCardsProps) {
  if (props.plans == null) {
    return <PlanCardsWithoutData {...props} />;
  }
  return <PlanCardsWithData {...props} />;
}

function PlanCardsWithoutData(props: PlanCardsProps) {
  const videoPlans = useRegisterPlans({ category: 'video' });
  const widgetPlans = useRegisterPlans({ category: 'widget' });
  const plans = useMemo(
    () => ({ widget: widgetPlans, video: videoPlans }),
    [widgetPlans, videoPlans]
  );
  return <PlanCardsWithData {...props} plans={plans} />;
}

const PlanCardsWithData = ({
  plans,
  redirectUrl = '/instagram-widget',
  onClick,
  loadingCard = false,
  allowFreeClick = false,
  showFree = true,
  defaultCategory = 'video',
  showInfo = false,
  onChangeCategory = () => {},
  showCategorySelector = false
}: PlanCardsProps) => {
  const { plan: currentPlan } = usePlan();
  const customPlanRef = useRef<any>(null);
  const [category, setCategory] = useState<PlanCategory>(defaultCategory);
  const [billingPeriod, setBillingPeriod] = React.useState<'month' | 'year'>('year');
  const { isAuthenticated } = useAuthentication();

  const { setIsActive } = React.useContext(BannerContext);

  useEffect(() => {
    if (localStorage.getItem('planPaymentItem') && isAuthenticated) {
      const item = JSON.parse(localStorage.getItem('planPaymentItem') || '');
      localStorage.removeItem('planPaymentItem');
      handleClick(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (item: any) => {
    if (currentPlan && currentPlan.isCustom) customPlanRef.current.show();
    else if (isFunction(onClick) && (item.price !== 0 || (allowFreeClick && item.price === 0))) {
      onClick(item);
    } else {
      Router.push(redirectUrl);
    }
  };

  const plansToShow = useMemo(() => {
    if (!plans || !plans[category]) return [];
    return plans[category].filter((p: any) => {
      if (p.price === 0 && !showFree) return false;
      if (p.category === 'video' && p.type === 'Pro') return false;
      return p.interval === billingPeriod || p.interval == null;
    });
  }, [billingPeriod, category, plans, showFree]);

  const handleCategory = (newCategory: PlanCategory) => {
    if (newCategory !== null) {
      setCategory(newCategory);
      onChangeCategory(newCategory);
    }
  };

  return (
    <PlansTabs
      showCategorySelector={showCategorySelector}
      onChangeCategory={handleCategory}
      category={category}
    >
      <>
        <PlanDiscountInfo>
          <PriceSwitch
            billingPeriod={billingPeriod}
            isBeforeEndDate={false}
            planPercent={category === 'widget' ? 18 : 30}
            onChangePeriod={setBillingPeriod}
          />
        </PlanDiscountInfo>

        <Wrapper>
          {plansToShow.map((plan, index) => {
            let price = roundPrice(billingPeriod === 'year' ? plan.price / 12 : plan.price);
            let offerPrice = '0';
            const isSpecialOffer =
              plan.type !== 'Free' && plan.specialOffer && plan.specialOffer.active;
            if (isSpecialOffer && plan.specialOffer) {
              offerPrice = roundPrice(
                billingPeriod === 'year' ? plan.specialOffer.price / 12 : plan.specialOffer.price
              );
            }

            let status: PlanCardStatus = 'default';
            if (loadingCard) {
              if (loadingCard === plan.id) {
                status = 'loading';
              } else {
                status = 'disabled';
              }
            }

            /* plan.updatingPrices = handleNewPrices(
              plan.category,
              plan.interval,
              isBeforeEndDate,
              plan.type
            ); */

            return (
              <PlanCard
                key={plan.id}
                isRecommended={plan.recommended === true}
                status={status}
                isSelected={currentPlan && currentPlan.id === plan.id}
                specialOffer={isSpecialOffer ? plan.specialOffer?.reason : undefined}
                plan={plan.type}
                title={plan.title}
                description={plan.description}
                features={plan.list ?? []}
                category={category}
                numberOfVideos={plan.videos}
                price={price}
                offerPrice={offerPrice}
                updatingPrices={plan.updatingPrices}
                isBeforeEndDate={false}
                onPlanSelection={() => {
                  gtmFireEvent({
                    event: 'onButtonClick',
                    category: getWoxoLocationFromPath(),
                    action: `select-${plan.category}-${plan.type}-plan`,
                    label: `id:${plan.id} @ type:${plan.type} @ category:${plan.category} @ interval:${plan.interval}`
                  });
                  handleClick(plan);
                }}
              />
            );
          })}
          <Dialog forwardRef={customPlanRef} useClose>
            <Box marginTop="36px" marginBottom="36px">
              You are using a custom plan. Please, contact the support team to manage it!
            </Box>
          </Dialog>
        </Wrapper>

        <Container maxWidth="lg">
          <PlanDiscountInfo>
            <b>7-Day</b> Money-Back guarantee on all <b>Premium</b> plans
          </PlanDiscountInfo>
        </Container>
      </>
    </PlansTabs>
  );
};

export type PlanCategory = 'video' | 'widget';
export type PlanType = 'Free' | 'Basic' | 'Pro' | 'Business' | 'Explorer';
type Plans = Record<PlanCategory, Plan[]>;

type Feature = {
  id: string;
  value: string;
  description?: string;
};

export interface Plan {
  id: string;
  price: number;
  type: PlanType;
  title: string;
  recommended?: boolean;
  interval: 'year' | 'month';
  specialOffer?: {
    active: boolean;
    price: number;
    reason: string;
  };
  description: string;
  list: Feature[];
  videos: number;
  category: PlanCategory;
  updatingPrices?: NewPrices;
}

interface PlanCardsProps {
  plans?: Plans;
  redirectUrl?: string;
  onClick: (plan: Plan) => void;
  loadingCard?: boolean | string;
  allowFreeClick?: boolean;
  showFree?: boolean;
  defaultCategory?: PlanCategory;
  showInfo?: boolean;
  onChangeCategory?: any;
  showCategorySelector?: boolean;
  updatingPrices?: NewPrices;
}

interface NewPrices {
  type: string;
  interval: string;
  price: number;
  nextPrice: number;
  percentage: number;
}

export default PlanCards;
