import { Box, Typography, ListItemText } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export const CardWrapper = styled(Box)({
  padding: '1rem',
  height: '100%',
  position: 'relative',
  paddingTop: 32,
  borderRadius: 16,
  maxWidth: '320px',
  minWidth: '320px',
  '&:hover': {
    cursor: 'cursor'
  }
});

export const Wrapper = styled(Box)({
  marginTop: 26,
  justifyContent: 'center',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
  rowGap: '1rem',
  justifyItems: 'center',
  position: 'relative'
});

export const PlanTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 28,
  lineHeight: '38px',
  color: '#414042',
  '@media only screen and (max-width: 320px)': {
    fontSize: 26
  }
});

export const CurrentPlan = styled(Typography)({
  width: '100%',
  height: 10,
  fontSize: 14,
  letterSpacing: '0px',
  lineHeight: '24px',
  color: 'var(--color-plan)'
});

export const PlanDescription = styled(Typography)({
  fontSize: 14,
  color: 'var(--color-plan)',
  fontWeight: 600,
  marginTop: 8,
  minHeight: 45,
  display: 'inline-block',
  verticalAlign: 'middle',
  width: '100%'
});

export const PlanFeatureAllow = styled(ListItemText)({
  padding: 0,
  margin: 0,
  marginBottom: 8,
  '& span, & p': {
    fontSize: '1rem',
    color: '#414042',
    textAlign: 'left'
  },
  '& p': {
    margin: 0
  },
  '&[data-tooltip]:hover': {
    textDecoration: 'underline'
  }
});

export const HeaderCard = styled(Box)({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    top: -30,
    left: 40
  }
});

export const PlanDiscountInfo = styled(Typography)({
  width: '100%',
  margin: '1.5rem auto',
  textAlign: 'center'
});

export const HeaderPlans = styled(Box)({
  gap: '3rem',
  '@media (max-width: 1024px)': {
    flexDirection: 'column-reverse',
    gap: 0
  }
});
