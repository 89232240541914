import Toast from 'toastr';
import toast from 'react-hot-toast';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const alert = (message, color, target) => {
  let div = document.getElementById('mission-control-alert');
  if (!div) {
    div = document.createElement('div');
    div.id = 'mission-control-alert';
    div.setAttribute('style', 'position:absolute;right:12px;top:12px;z-index: 22212121212121212;');
    if (target) target.appendChild(div);
    else document.body.appendChild(div);
  }
  let bgColor = '';
  let icon = `<svg  height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>`;
  switch (color) {
    case 'info':
      bgColor = '#58abc3';
      break;
    case 'error':
      icon = `<svg height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>`;
      bgColor = '#ca5e58';
      break;
    case 'warning':
      bgColor = '#fcc412';
      break;
    default:
      bgColor = '#73b573';
  }
  const a = document.createElement('div');
  a.setAttribute(
    'style',
    `box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    color: #000;
    transition: all 1s ease 0s;
    margin-bottom: 12px;
    opacity: 1;
    width: 300px;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    align-items: stretch;`
  );
  a.innerHTML = `<div style="display: flex;align-items: center;justify-content: center;width: 38px;background-color: ${bgColor};border-bottom-left-radius:6px;border-top-left-radius:6px;color:#fff;">${icon}</div><div style="padding: 10px;font-size:12px;line-height:1.5;flex: 1;">${message}</div>`;
  div.appendChild(a);
  setTimeout(() => {
    a.style.opacity = '1';
  }, 500);
  setTimeout(() => {
    a.style.opacity = '0';
    setTimeout(() => {
      a.parentNode.removeChild(a);
    }, 500);
  }, 5000);
};

const msg = (message, title, id, closeButton = true) => {
  return (
    <span>
      <IconButton
        onClick={() => (id ? toast.dismiss(id) : toast.dismiss())}
        variant="outlined"
        size="small"
        style={{
          float: 'right',
          width: '0.8rem',
          height: '0.8rem',
          fontSize: '0.8rem',
          marginLeft: '0.5rem',
          marginRight: '-0.8rem',
          marginTop: '-0.4rem',
          display: closeButton ? 'inherit' : 'none'
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
      {title ? (
        <>
          <span>
            <b>{title}</b>
          </span>
          <br />
        </>
      ) : null}
      <span>{message}</span>
    </span>
  );
};

const notifications = {
  info: (message, title) => {
    // Toast.info(message, title);
    toast((t) => msg(message, title, t.id), {
      icon: '💁'
    });
  },
  error: (message, title, config) => {
    if (config && config.variant === 'compact') alert(message, 'error', config.target);
    //Toast.error(message, title);
    else toast.error((t) => msg(message, title, t.id));
  },
  success: (message, title, config = {}, closeButton) => {
    if (config && config.variant === 'compact') alert(message, 'success', config.target);
    else {
      // Toast.success(message, title, {
      //   timeOut: (config && config.timeOut) || 5000,
      //   closeDuration: (config && config.closeDuration) || 5000,
      //   closeHtml: (config && config.closeHtml) || null,
      //   closeButton: (config && config.closeButton) || false
      // });
      toast.success(
        (t) => msg(message, title, t.id, closeButton),
        config ? config : { duration: 10000 }
      );
    }
  },
  warning: (message, title) => {
    // Toast.warning(message, title);
    toast((t) => msg(message, title, t.id), {
      icon: '⚠️'
    });
  },
  text: (message, title) => {
    // Toast.warning(message, title);
    toast((t) => msg(message, title, t.id));
  },
  remove: () => {
    Toast.remove();
  }
};

export default notifications;
