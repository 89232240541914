export const freColor = '#e0e0e0cc';
export const freeColorHover = '#e0e0e0ff';

export const proPlanColor = '#0086FF';
export const proPlanColorHover = '#0086FF';

export const basicPlanColor = '#7C00FF';
export const basicPlanColorHover = '#6f00e5';

export const businessPlanColor = '#40BF00';
export const businessPlanColorHover = '#39aa00';
