import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { PricingTableSection } from '../PricingTabs/PricingTableSection';
import { Button, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    width: '100%',
    borderRadius: 16,
    boxShadow: 'none',
    textAlign: 'center',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    width: '45%',
    border: '2px solid #7c11f1',
    borderRadius: 16,
    marginBottom: '1rem',
    margin: '0 auto',
    minHeight: 56,
    textAlign: 'center',
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expandIcon: {
    color: '#000000'
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}))(MuiAccordionDetails);

export const TableAccordion = () => {
  return (
    <div>
      <Accordion square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 700 }}>See all features comparison</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            <PricingTableSection />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
