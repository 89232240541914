import { PropsWithChildren, useState } from 'react';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';
import { Button, Container } from '@material-ui/core';
import { Plan, PlanCategory } from '.';
import { usePlan } from '~/services/stripe.services';
import { basicPlanColor } from './colors/colors';
import { PricingTableSection } from '../PricingTabs/PricingTableSection';
import { TableAccordion } from './TableAccordion';

const CustomTabs = withStyles({
  root: {
    width: '100%'
  },
  indicator: {
    display: 'none'
  }
})(Tabs);

type StyledTabProps = {
  label: string;
};

const CustomTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: '50%',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Open sans, sans-serif',
      backgroundColor: '#e8e8e8',
      borderRadius: '16px 16px 0 0',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic - bezier(0.4, 0, 0.2, 1)',
      transitionDuration: '150ms',
      opacity: 0.75,
      '&:hover': {
        opacity: 1
      },
      '&$selected': {
        opacity: 1,
        backgroundColor: '#ffffff',
        fontWeight: 600,
        borderBottom: 'none',
        borderTop: `5px solid ${basicPlanColor}`,
        borderLeft: `1px solid ${basicPlanColor}`,
        borderRight: `1px solid ${basicPlanColor}`
      }
    },
    selected: {
      borderBottomWidth: 0,
      background: '#ffffff',
      '& $wrapper': {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '0 0 16px 16px'
  },
  padding: {
    padding: theme.spacing(2)
  },
  contentWrapper: {
    padding: '36px 0'
  },
  bgSkyBlue: {
    backgroundColor: '#F9FBFD'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
    gap: 16
  },
  headline: {
    textAlign: 'center',
    fontSize: '2.20rem',
    marginTop: '0 auto'
  },
  priceSwitch: {
    width: '100%',
    margin: '0 auto'
  },
  featureButton: {
    borderRadius: '4px',
    width: '40%',
    margin: '1rem auto'
  },
  featuresTableContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    gap: 16
  }
}));

type PricingTabsProps = PropsWithChildren<{
  category: PlanCategory;
  showCategorySelector: boolean;
  onChangeCategory: (category: PlanCategory) => void;
}>;

export const PlansTabs = ({
  category,
  showCategorySelector,
  onChangeCategory,
  children
}: PricingTabsProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(category === 'video' ? 0 : 'widget');

  const handleChange = (_event: React.ChangeEvent<{}>, tabValue: number) => {
    setValue(tabValue);
    tabValue === 0 ? onChangeCategory('video') : onChangeCategory('widget');
  };

  return (
    <Container maxWidth="xl">
      <div className={clsx(classes.root)}>
        {showCategorySelector ? (
          <div className={clsx(classes.bgSkyBlue)}>
            <CustomTabs value={value} onChange={handleChange}>
              <CustomTab label="Video maker plans" />
              <CustomTab label="Social widget plans" />
            </CustomTabs>
          </div>
        ) : null}
        <Container maxWidth={category === 'video' ? 'xl' : 'xl'} className={classes.contentWrapper}>
          <div>{children}</div>
        </Container>
        {category === 'video' ? <TableAccordion /> : null}
      </div>
    </Container>
  );
};
