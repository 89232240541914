const textAligns = [
  {
    name: 'Centered',
    value: 'center'
  },
  {
    name: 'Bottom',
    value: 'bottom'
  },
  {
    name: 'Top',
    value: 'top'
  },
  {
    name: 'Centered, Bottom, Centered',
    value: 'center-bottom-center'
  },
  {
    name: 'Centered, Top, Centered',
    value: 'center-top-center'
  },
  {
    name: 'Top, Centered, Top',
    value: 'top-center-top'
  },
  {
    name: 'Bottom, Centered, Bottom',
    value: 'bottom-center-bottom'
  }
];

export default textAligns;
