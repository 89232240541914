import CheckIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { green, red } from '@material-ui/core/colors';

import { PlansDetailsTable } from './PlansDetailsTable';
import type { TableFeature } from './PlansDetailsTable';

const createData = (
  feature: TableFeature,
  freePlan: JSX.Element | string,
  explorePlan: JSX.Element | string,
  basicPlan: JSX.Element | string,
  proPlan: JSX.Element | string
) => {
  return { feature, freePlan, explorePlan, basicPlan, proPlan };
};

const checkIcon = <CheckIcon style={{ color: green[500] }} />;
const crossIcon = <ClearRoundedIcon style={{ color: red[500] }} />;

const rowsFirstSection = [
  createData({ text: 'Remove watermark' }, crossIcon, checkIcon, checkIcon, checkIcon),
  createData({ text: 'Monthly video credits' }, '20', '500', '1000', '2000'),
  createData({ text: 'Monthly downloaded videos' }, 'Limited', '50', '100', '500'),
  createData({ text: 'Monthly dynamic videos' }, 'Limited', '1000', '5000', '25000'),
  createData(
    { text: 'Free stock photos, graphics, videos, fonts and audio' },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    { text: 'Upload your own photos, videos and audio' },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Copyright-free music' }, crossIcon, checkIcon, checkIcon, checkIcon),
  createData({ text: 'Commercial usage rights' }, crossIcon, checkIcon, checkIcon, checkIcon)
];

const rowsSecondSection = [
  createData(
    {
      text: 'AI video generator',
      hoverText: 'AI-driven technology automatically creates stunning visuals'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Premium AI backgrounds',
      hoverText: 'Access to 20+ AI generated backgrounds for your videos'
    },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    { text: 'Premium AI content categories', hoverText: 'Enjoy up to 14 content categories' },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Premium AI voice-overs',
      hoverText: 'Access to 30+ AI voiceovers by mood and accents'
    },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Video style customization' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData({ text: 'Background music' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData({ text: 'Text style customization ' }, checkIcon, checkIcon, checkIcon, checkIcon)
];

const rowsThirdSection = [
  createData(
    {
      text: 'AI video generator',
      hoverText: 'AI-driven technology automatically creates stunning visuals'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Premium AI backgrounds',
      hoverText: 'Access to 20+ AI generated backgrounds for your videos'
    },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Premium AI content suggestions',
      hoverText: 'Unlock exclusive AI-powered recommendations'
    },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Premium AI voice-overs',
      hoverText: 'Access to 30+ AI voiceovers by mood and accents'
    },
    crossIcon,
    checkIcon,
    checkIcon,
    checkIcon
  )
];

const rowsFourthSection = [
  createData(
    { text: 'Drag and drop background images or video clips' },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Add and remove scenes' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData({ text: 'Auto-generate video from text' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    { text: 'Shuffle video styling', hoverText: 'Randomize style in all videos with one click.' },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Bulk text & music customization',
      hoverText: 'Apply text style and music to all videos at once'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Video resizing' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    { text: 'Download all at once as MP4, JPG or GIF' },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  )
];

const rowsFifthSection = [
  createData({ text: '300+ free video templates' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    {
      text: 'Bulk video editing',
      hoverText:
        'Simplifies the process of creating and editing multiple videos at once with a single click'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Apply to all feature',
      hoverText:
        'Apply customization, media, captions, audio or logo to a group of videos or to all with one click'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Clone multiple videos' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    {
      text: 'Branding kit',
      hoverText: 'Upload your logo, brand font and colors, and apply to all the videos'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Text style & animation' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    {
      text: 'Export and import CSV file',
      hoverText: 'Convert spreadsheet data into video content.'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Import Google Sheets',
      hoverText: 'G-sheet integration to create, edit & share videos at scale simultaneously'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Video resizing' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData({ text: 'Scene length customization' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData({ text: 'Voice over' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    { text: 'Export all at once as MP4, JPG or GIF' },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Landing page with the batch of videos exported',
      hoverText:
        "Each time you export videos you'll get a unique landing page with the batch of videos generated"
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  )
];

const rowsSixthSection = [
  createData(
    { text: '20+ categories free video templates' },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    { text: 'Save designs as templates', hoverText: 'Set up custom variables' },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Canva resizing' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    {
      text: 'Bulk text distribution',
      hoverText:
        'Input captions and distribute them to one, many or all your videos with once click'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Bulk apply media',
      hoverText:
        'Add image or video clips and distribute them to one, many or all your videos with once click'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData({ text: 'Clone multiple videos' }, checkIcon, checkIcon, checkIcon, checkIcon),
  createData(
    {
      text: 'Export and import CSV file',
      hoverText: 'Convert spreadsheet data into video content'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Import Google Sheets',
      hoverText: 'G-sheet integration to create, edit & share videos at scale simultaneously'
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    { text: 'Export all at once as MP4, JPG or GIF' },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  ),
  createData(
    {
      text: 'Landing page with the batch of videos exported',
      hoverText:
        "Each time you export videos you'll get a unique landing page with the batch of videos generated"
    },
    checkIcon,
    checkIcon,
    checkIcon,
    checkIcon
  )
];

const supportSection = [
  createData(
    { text: 'Customer Support - Business hours' },
    'Email and chat support for first 30 days',
    'Email and chat support',
    'Email and chat support',
    'Priority email, chat and video-call support'
  )
];

export const PricingTableSection = () => {
  return (
    <>
      <PlansDetailsTable rows={rowsFirstSection} header="Video generation & export" tableHeader />
      <PlansDetailsTable
        rows={rowsSecondSection}
        header="WOXO Video Maker tools"
        subHeader="Idea to videos"
        subSubHeader="Transform ideas into stunning customizable videos."
      />
      <PlansDetailsTable
        rows={rowsThirdSection}
        subHeader="Text to videos"
        subSubHeader="AI video generator from a single keyword or topic"
      />
      <PlansDetailsTable
        rows={rowsFourthSection}
        subHeader="Bulk creator"
        subSubHeader="Text to video generator to generate videos with little customization"
      />
      <PlansDetailsTable
        rows={rowsFifthSection}
        subHeader="Sheet to videos"
        subSubHeader="Converts content from spreadsheets into a batch of videos quickly"
      />
      <PlansDetailsTable
        rows={rowsSixthSection}
        subHeader="Template to video"
        subSubHeader="Design a template once and batch videos forever"
      />
      <PlansDetailsTable rows={supportSection} header="Support" />
    </>
  );
};
