import { Box, List, ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import { styled, makeStyles, DefaultTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import * as React from 'react';
import { PlanType } from '.';
import { FeaturePopover } from './FeaturePopover';
import { MouseOverPopover } from './MouseOverPopover';

interface Props {
  plan: Exclude<PlanType, 'Business'>;
}

type FeaturesList = {
  [k in Exclude<PlanType, 'Business'>]: FeaturesDesc[];
};

type FeaturesDesc = {
  desc: string;
  allowed: boolean;
  new?: boolean;
  hasHover?: boolean;
  hoverText?: string;
};

const features: FeaturesList = {
  Free: [
    { desc: '100 video credits / month', allowed: true },
    /* {
      desc: 'Limited customer support',
      allowed: true,
      hasHover: true,
      hoverText: 'Email and chat support for first 30 days.'
    }, */
    { desc: 'Watermark', allowed: true }
  ],
  Explorer: [
    { desc: '500 video credits / month', allowed: true },
    {
      desc: 'Customer support',
      allowed: true,
      hasHover: true,
      hoverText: 'Email and chat support during business hours.'
    },
    { desc: 'No watermark', allowed: true }
    /*  {
      desc: 'Premium AI Features',
      allowed: true,
      hoverText: 'AI generated backgrounds, voice overs and categories.',
      hasHover: true
    } */
  ],
  Basic: [
    { desc: '2000 video credits / month', allowed: true },
    {
      desc: 'Priority customer support',
      allowed: true,
      hasHover: true,
      hoverText: 'Email and chat support during business hours.'
    },
    { desc: 'No watermark', allowed: true }
    /* {
      desc: 'Premium AI Features',
      allowed: true,
      hoverText: 'AI generated backgrounds, voice overs and categories.',
      hasHover: true
    } */
  ],
  Pro: [
    { desc: '4000 video credits / month', allowed: true },
    {
      desc: 'Priority customer support',
      allowed: true,
      hasHover: true,
      hoverText: 'Priority email, chat and video-call support.'
    },
    { desc: 'No watermark', allowed: true },
    {
      desc: 'Premium AI Features',
      allowed: true,
      hoverText: 'AI generated backgrounds, voice overs and categories.',
      hasHover: true
    }
  ]
};

const NewBadge = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  color: 'white',
  fontSize: 16,
  width: 80,
  height: 35,
  backgroundColor: '#ffcd30',
  borderRadius: 15,
  '@media (max-width: 1300px)': {
    width: 75,
    height: 30,
    position: 'inherit',
    fontSize: 15
  }
});

const useStyles = makeStyles<any, { isPlansRoute: boolean }>((theme) => ({
  featureContainer: ({ isPlansRoute }) => ({
    height: 'auto'
  }),
  features: {
    minHeight: '158px'
  },
  planIconBasic: {
    color: '#7c11f1'
  },
  planIconPro: {
    color: '#0086ff'
  }
}));

export const PlanFeatures = ({ plan }: Props) => {
  const basic = plan === 'Basic';
  const pro = plan === 'Pro';

  const { asPath } = useRouter();
  const classes = useStyles({ isPlansRoute: asPath === '/plans' });
  return (
    <Box className={classes.featureContainer}>
      <List component={'ul'} className={classes.features}>
        {features[plan].map((feat: FeaturesDesc, index: number) => {
          return (
            <ListItem key={index} style={{ position: 'relative', padding: 0 }} disableGutters>
              <ListItemIcon
                style={{
                  minWidth: 35
                  // color: feat.allowed ? 'rgb(60, 60, 60)' : 'rgba(0, 0, 0, 0.54)',
                }}
                className={clsx(basic && classes.planIconBasic, pro && classes.planIconPro)}
              >
                {feat.allowed ? <CheckCircle /> : <RadioButtonUnchecked />}
              </ListItemIcon>
              {feat.hasHover ? (
                <FeaturePopover text={feat.hoverText as string}>
                  <p style={{ textDecoration: 'underline' }}>{feat.desc}</p>
                </FeaturePopover>
              ) : (
                <p style={{ textDecoration: 'none' }}>{feat.desc}</p>
              )}
              {feat.new && <NewBadge>New</NewBadge>}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
