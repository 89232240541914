const styles = {
  WoxoIcon: {
    width: '75px'
  },
  SubTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '19px',
    color: '#414042'
  },
  Header: {
    marginTop: '20px',
    fontSize: '26px',
    fontWeight: '700',
    lineHeight: '27px',
    color: '#414042'
  },
  Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    position: 'relative',
    flexDirection: 'column'
  },
  Card: {
    boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.01), 0px 2px 8px rgba(0, 0, 0, 0.1)',
    padding: '30px',
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
    transition: 'all .5s',
    borderRadius: '8px',
    fontSize: '12px',
    lineHeight: '1.5',
    '.loading': {
      paddingTop: '22px'
    }
  },
  Logout: {
    padding: '10.5px 24px',
    height: '50px',
    background: '#FFFFF',
    border: '1px solid #D3D2D7',
    textTransform: 'none',
    fontSize: '18px',
    color: '#000',
    borderRadius: '40px'
  },
  ResendEmail: {
    minWidth: '180px',
    height: '50px',
    fontSize: '18px',
    boxShadow: 'none',
    borderRadius: '45px',
    textTransform: 'none',
    backgroundImage: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
    color: '#fff'
  },
  Link: {
    color: 'rgb(124, 1, 255)',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  Loading: {
    position: 'absolute',
    top: 1,
    left: 1,
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    width: 'calc(100% - 2px)',
    height: '10px'
  }
};

export default styles;
