export default function getWoxoLocationFromPath() {
  const route = window && window.location.pathname;
  const stage =
    route.includes('widget') || route.includes('browse')
      ? 'widget'
      : route.includes('bulk-creator')
      ? 'bulk-creator'
      : route.includes('idea-to-video')
      ? 'idea-to-video'
      : route.includes('sheet-to-video')
      ? 'template-to-video'
      : route.startsWith('/video')
      ? 'sheet-to-videos'
      : route.includes('plans')
      ? 'pricing-page'
      : route.includes('dashboard') && route.includes('settings')
      ? 'my-account'
      : 'homepage';

  return stage;
}
