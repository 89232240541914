import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { basicPlanColor } from '../PlanCard/colors/colors';

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 64,
      height: 32,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: basicPlanColor,
      '&$checked': {
        transform: 'translateX(32px)',
        color: basicPlanColor,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#ffffff',
          borderColor: theme.palette.grey[500]
        }
      }
    },
    thumb: {
      width: 28,
      height: 28,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {}
  })
)(Switch);

type PriceSwitchProps = {
  planPercent: number;
  billingPeriod: 'year' | 'month';
  isBeforeEndDate: boolean;
  onChangePeriod: Dispatch<SetStateAction<'year' | 'month'>>;
};

export const PriceSwitch = ({
  billingPeriod,
  planPercent,
  onChangePeriod,
  isBeforeEndDate
}: PriceSwitchProps) => {
  const checked = billingPeriod === 'year';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChangePeriod(event.target.checked ? 'year' : 'month');

  return (
    <div>
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={1}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>Monthly</Grid>
        <Grid item>
          <CustomSwitch checked={checked} onChange={handleChange} />
        </Grid>
        <Grid item>
          <div>Annual</div>
        </Grid>
      </Grid>
      <Typography style={{ textAlign: 'center', fontWeight: 600, margin: '1rem 0' }}>
        {isBeforeEndDate
          ? `Save over ${planPercent}% with annual subscriptions`
          : `Save more than ${planPercent}% with annual plans`}
      </Typography>
    </div>
  );
};
