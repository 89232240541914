import type { PlanType } from '../index';

export const plansColors: Record<
  PlanType,
  {
    primary: string;
    accent: string;
    hover: string;
    font: string;
    link?: string;
    title?: string;
    btnBG?: string;
  }
> = {
  Free: {
    primary: '#414042',
    accent: '#7e8082',
    hover: '#121212a5',
    font: '#fff',
    title: '#414042',
    btnBG: '#414042'
  },
  Explorer: {
    primary: '#414042',
    accent: '#414042',
    hover: '#252525',
    font: '#fff',
    title: '#252525',
    btnBG: '#2962FF'
  },
  Basic: {
    primary: '#414042',
    accent: '#414042',
    hover: '#252525',
    font: '#fff',
    title: '#252525',
    btnBG: '#6200EA'
  },
  Pro: {
    primary: '#0086ff',
    accent: '#0086ff',
    hover: '#006fff',
    font: '#fff',
    title: '#414042',
    btnBG: '#414042'
  },
  Business: {
    primary: '#5BC005',
    accent: '#5BC005',
    hover: '#3b7f01',
    font: '#fff',
    title: '#414042',
    btnBG: '#414042'
  }
};
