import { unstable_batchedUpdates } from 'react-dom';

import axios from './axios';
import api from './api';
import { API_HOST } from './app.config';
import { isArray, isObject } from '../helpers/javascript';
import translate from '../data/translations';
import { isBrowser } from '../helpers/browser';
import { useAuthHeaders } from './security.service';

import { useTeamStore } from '~/store/team-store/use-team.store';

import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useEffect, useState } from 'react';

export function transformTeam(team) {
  const { brandings, ...teamDraft } = team;
  const branding = isArray(brandings) ? brandings[0] : null;
  return {
    ...teamDraft,
    id: teamDraft.id ?? teamDraft._id,
    branding
  };
}

const get3rdPartyUserId = () => {
  const hjUserId =
    (window.getCookie &&
      typeof window.getCookie === 'function' &&
      window.getCookie('__hjUserId')) ||
    '';
  const gaUserId =
    (window.getCookie &&
      typeof window.getCookie === 'function' &&
      window.getCookie('_ga') &&
      `${window.getCookie('_ga').split('.')[2]}.${window.getCookie('_ga').split('.')[3]}`) ||
    '';
  const audience = isBrowser() ? localStorage.getItem('user-audience') || 'widget' : 'widget';
  return { hjUserId, gaUserId, audience };
};

export const create = ({ jwt, userTags }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/teams/user`,
      method: 'POST',
      headers: { Authorization: `Bearer ${jwt}` },
      data: { ...get3rdPartyUserId(), userTags }
    })
      .then((response) => {
        if (isObject(response.data)) {
          unstable_batchedUpdates(() => {
            useTeamStore.getState().setTeam(response.data);
          });
          resolve(response.data);
        } else reject(new Error(translate('providerSignUpError', 'en')));
      })
      .catch(() => {
        reject(new Error(translate('invalidLoginError', 'en')));
      });
  });

export function useInviteToTeam() {
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/invite`;
  const method = 'POST';

  return useSWRMutation([url, method], async ([url, method], { arg: { emails } }) => {
    return axios({
      url,
      method,
      headers: await getAuthHeaders(),
      data: { emails }
    })
      .then((response) => {
        if (response.data.success && isArray(response.data.success)) {
          return response.data.success;
        } else {
          throw new Error(translate('error.network', 'en'));
        }
      })
      .catch(() => {
        throw new Error(translate('error.network', 'en'));
      });
  });
}

export const acceptInvitation = ({ code }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/teams/invite/accept`,
      method: 'POST',
      data: { code }
    })
      .then((response) => {
        if (response.data.success) resolve(response.data.success);
        else reject(new Error(translate(response.data.error, 'en')));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

// TODO: refactor - move to hooks folder
export function useTeam() {
  const team = useTeamStore((state) => state.team);
  const [teamId, setTeamId] = useState(team.id);

  useEffect(() => {
    if (!teamId) {
      unstable_batchedUpdates(() => {
        useTeamStore
          .getState()
          .initTeam()
          .then((team) => setTeamId(team.id));
      });
    }
  }, [teamId]);

  return team;
}

export function useTeamMembers() {
  const { data: defaultTeam } = useDefaultTeam();
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/members`;
  const method = 'GET';
  return useSWR(
    defaultTeam ? [url, method] : null,
    async () => {
      return axios({
        url: `${API_HOST}/custom/teams/members`,
        method,
        headers: { ...(await getAuthHeaders()), 'X-Mission-Control-Team': defaultTeam._id }
      })
        .then((response) => {
          if (response.data.success) {
            return response.data.success;
          } else {
            throw new Error(translate(response.data.error, 'en'));
          }
        })
        .catch(() => {
          throw new Error(translate('error.network', 'en'));
        });
    },
    { fallbackData: [] }
  );
}

export function useRemoveFromTeam() {
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/members`;
  const method = 'DELETE';
  return useSWRMutation([url, method], async ([url, method], { arg: userId }) => {
    return axios({
      url: `${url}/${userId}`,
      method,
      headers: await getAuthHeaders()
    })
      .then((response) => {
        if (response.data.success) {
          return response.data.success;
        } else {
          throw new Error(translate(response.data.error, 'en'));
        }
      })
      .catch(() => {
        throw new Error(translate('error.network', 'en'));
      });
  });
}

export function useTeams() {
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/user`;
  const method = 'GET';

  return useSWR(method + '-' + url, async () => {
    return axios({
      url,
      method,
      headers: await getAuthHeaders()
    })
      .then((response) => {
        if (response.data.success) {
          return response.data.success;
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch(() => {
        throw new Error(translate('error.network', 'en'));
      });
  });
}

export function useTeamOwner() {
  const { getAuthHeaders } = useAuthHeaders();
  const team = useTeam();
  const url = `${API_HOST}/custom/teams/userOwner`;
  const method = 'GET';

  return useSWR(`${team}+${method}+${url}`, async () => {
    return axios({
      url,
      method,
      headers: await getAuthHeaders()
    }).then((response) => {
      if (response.data.success) {
        return response.data.user;
      } else {
        return response.data.error;
      }
    });
  });
}

const defaultTeamURL = `${API_HOST}/custom/teams/default`;

export function getDefaultTeam() {
  return api({
    url: defaultTeamURL
  })
    .then((response) => {
      if (response.data.success) {
        return response.data.success;
      } else {
        return false;
        // throw new Error(response.data.error);
      }
    })
    .catch((err) => {
      console.log({ err });
      // throw new Error(translate('error.network', 'en'));
      return false;
    });
}

export function useDefaultTeam() {
  return useSWR(defaultTeamURL, getDefaultTeam);
}

export const saveBranding = (branding) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/teams/branding`,
      method: 'POST',
      data: { branding }
    })
      .then((response) => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch((e) => {
        reject();
      });
  });

export const changeCurrentTeam = (team) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/profiles/change-current-team`,
      method: 'POST',
      data: { team }
    })
      .then((response) => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch((e) => {
        reject();
      });
  });
