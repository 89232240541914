import { useRouter } from 'next/router';

const useSEO = ({ seo }) => {
  const { asPath } = useRouter();

  return {
    title:
      (seo && seo.title) ||
      `WOXO - ${
        asPath === '/'
          ? 'Home'
          : asPath
              .split('/')[1]
              .replace(/-/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
      } | Make videos & social content. Faster`,
    canonical: (seo && seo.canonical) || 'https://woxo.tech',
    description:
      (seo && seo.description) ||
      'Are you spending too much time creating video and social content Don’t go anywhere, WOXO is about to give you back time!',
    keywords:
      (seo && seo.keywords) ||
      'woxo, ai video generator, text to video, text to video maker, ai created video, youtube shorts maker, sheet to ' +
        'videos, video automation, ai video tool, ai video maker, text to video ai, spreadsheet to video maker, bulk edit' +
        ' videos, batching videos, ai video, widgets, widget, profile',
    'og:title': (seo && seo['og:title']) || 'WOXO | Make Videos & Social Content. Faster',
    'og:url': (seo && seo['og:url']) || 'https://woxo.tech',
    'og:description':
      (seo && seo['og:description']) ||
      'Are you spending too much time creating video and social content Don’t go anywhere, WOXO is about to give you back time!',
    'og:image':
      (seo && seo['og:image']) ||
      'https://res.cloudinary.com/dakp804eh/image/upload/v1622122103/woxo-landing-page/Header2.png',
    'og:image:width': (seo && seo['og:image:width']) || '1200',
    'og:image:height': (seo && seo['og:image:height']) || '630',
    'twitter:card': (seo && seo['twitter:card']) || 'summary_large_image',
    'twitter:title': (seo && seo['twitter:title']) || 'WOXO | Make Videos & Social Content. Faster',
    'twitter:url': (seo && seo['twitter:url']) || 'https://woxo.tech',
    'twitter:description':
      (seo && seo['twitter:description']) ||
      'Are you spending too much time creating video and social content Don’t go anywhere, WOXO is about to give you back time!',
    'twitter:image':
      (seo && seo['twitter:image']) ||
      'https://res.cloudinary.com/dakp804eh/image/upload/v1622122103/woxo-landing-page/Header2.png'
  };
};

export default useSEO;
