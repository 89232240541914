export const gtmFireEvent = ({
  event = 'onButtonClick',
  category = 'general',
  action = '',
  label = '',
  params = {},
  eventCallback = () => {}
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer?.push({
    event: event,
    category: category,
    action: action,
    label: label,
    params: params,
    eventCallback
  });
};
