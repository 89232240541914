import useSWRImmutable from 'swr/immutable';
import { appsumoPrices, prices } from '../services/stripe.services';

function findPrices({ category, type }) {
  if (type === 'appsumo') {
    return appsumoPrices({ category });
  }
  return prices({ category });
}

const useRegisterPlans = ({ category, type = 'stripe' }) => {
  const { data: plans } = useSWRImmutable(['plans', category, type], () => {
    return findPrices({ category, type });
  });

  return plans;
};

export default useRegisterPlans;
