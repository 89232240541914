import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      padding: '1.25rem 1rem',
      [theme.breakpoints.up('md')]: {
        maxWidth: '65%',
        padding: '1.25rem 0'
      }
    },
    header: {
      fontSize: '2rem'
    },
    subheader: {
      fontSize: '1.75rem',
      fontWeight: 600
    },
    tableCell: {
      fontSize: '1.5rem',
      fontWeight: 500
    },
    tableFeature: {
      width: '50%'
    },
    tableContainerText: {
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column'
    }
  })
);
