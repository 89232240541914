import axios from 'axios';
import audios from '../data/template-generator/audios';

import fonts from '../data/template-generator/fonts';
import presets from '../data/template-generator/presets';
import gradient from '../data/template-generator/gradientType';

import moods from '../data/template-generator/moods';
import textaligns from '../data/template-generator/textaligns';
import transitions from '../data/template-generator/transitions';

import cloneDeep from 'lodash/cloneDeep';

import chroma from 'chroma-js';

export const displayLog = (Text = '', consoleCSS = '', objLog) => {
  if (objLog) {
    console.log(Text, consoleCSS, objLog);
  }
};

export const isMediaUrl = async (value) => {
  const videoExt = /mp4|webm|3gp|mpg|mpeg|mov|avi/g;
  const imageExt = /apng|avif|gif|jpg|jpeg|heic|heif|jfif|pjpeg|pjp|png|svg|webp/g;

  const validation =
    value.match(videoExt) !== null ||
    value.match(imageExt) !== null ||
    value.includes('player.vimeo');

  if (!validation) {
    const response = await axios.get(value);
    const type = response.headers['content-type'];

    return (
      type.match(videoExt) !== null ||
      type.match(imageExt) !== null ||
      value.includes('player.vimeo')
    );
  } else return validation;
};

export const isAudioUrl = async (value) => {
  const musicExt = /mp3|m4a|wav|wma|flac|tiff|aif|acc|ogg|oga/g;

  const validation = value.match(musicExt) !== null;

  if (!validation) {
    const response = await axios.get(value);
    const type = response.headers['content-type'];

    return type.match(musicExt) !== null;
  } else return validation;
};

export const isImageUrl = async (value) => {
  const imageExt = /apng|avif|gif|jpg|jpeg|heic|heif|jfif|pjpeg|pjp|png|svg|webp/g;

  const validation = value.match(imageExt) !== null;

  if (!validation) {
    const response = await axios.get(value);
    const type = response.headers['content-type'];

    return type.match(imageExt) !== null;
  } else return validation;
};

export const isFontUrl = async (value) => {
  const fontExt = /ttf|ttf2|woff2|woff/g;

  const validation = value.match(fontExt) !== null;

  if (!validation) {
    const response = await axios.get(value);
    const type = response.headers['content-type'];

    return type.match(fontExt) !== null;
  } else return validation;
};

const isBrowser = () => typeof window !== 'undefined';
export const emptyFn = () => {};
export const isString = (value) => typeof value === 'string';
export const isArray = (value) => Array.isArray(value);
export const isObject = (value) =>
  value && typeof value === 'object' && value.constructor === Object;
export const isFunction = (val) => typeof val === 'function';
export const isTextAlign = (value) => textaligns.find((p) => p.name === value);
export const isTransition = (value) => transitions.find((p) => p.name === value);
export const isAudio = (value) => audios.find((p) => p.name === value);
export const isMood = (value) => moods.find((p) => p.value === value);
export const isImage = (url) =>
  isString(url) &&
  /(https:\/\/images.unsplash.com\/photo-)|(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)(\?.*)?$/.test(url);
export const isVideo = (url) => isString(url) && /player\.vimeo\.com|(\.mp4)(\?.*)?$/.test(url);
export const isFont = (value) => fonts.includes(value);
export const isTextStyle = (value) => presets.find((p) => p.name === value);
export const isGradientType = (value) =>
  gradient.find((p) => p.name.toLowerCase() === value.toLowerCase());
export const isColor = (value) => {
  return chroma.valid(value);
};
export const isUrl = (string) => {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
};
export const isMobile = () => {
  if (isBrowser()) {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  return false;
};
export const isUUID2 = (val) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(val);
export const isUUID = (val) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(val) ||
  /^[A-Za-z0-9_-]{10}$/i.test(val);

export const stringToColor = (string) => {
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  const intToRGB = (i) => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - c.length) + c;
  };

  return intToRGB(hashCode(string));
};

export const clone = (obj) => {
  try {
    return cloneDeep(obj);
  } catch (error) {}
  return {};
  /*
try {

    return JSON.parse(JSON.stringify(obj));
  } catch (error) {}
  return {};*/
};

export const hashCode = (value) => {
  const str = value;
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

export const humanizeDuration = (duration) => {
  if (duration < 60) return `00:${duration < 10 ? `0${duration}` : duration}`;
  const minutes = Math.trunc(duration / 60);
  const rest = duration % 60;
  if (rest === 0) return `${minutes < 10 ? `0${minutes}` : minutes}:00`;
  return `${minutes < 10 ? `0${minutes}` : minutes}:${rest < 10 ? `0${rest}` : rest}`;
};
export const checkRemote = async (url) => {
  try {
    const result = await axios.head(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const extractName = (v) => {
  if (isUrl(v)) {
    const url = v.split('/');
    return url[url.length - 1].split('.')[0];
  }
  return v;
};

export const extractNameLeaveWords = (n) => {
  if (extractName(n)) {
    // const name = extractName(n).split('_-_')[1];
    const name = extractName(n);
    if (name !== undefined) {
      return name.replace(/_/g, ' ');
    }
    const nameAux = extractName(n).split('_');
    if (nameAux.length > 1) {
      return `${nameAux[0]} ${nameAux[1]}`;
    }
  }
  return extractName(n);
};

export const applyFontStyle = (value, fn = (f) => {}) => {
  setTimeout(() => {
    const text = document.createElement('span');
    if (isUrl(value)) {
      const url = `url(${value})`;
      if (!(document.fonts && document.fonts.check(`1px ${extractName(value)}`))) {
        const font = new FontFace(extractName(value), url);
        font
          .load()
          .then(function (loadedFont) {
            document.fonts.add(loadedFont);
            if (text) text.style.fontFamily = `"${extractName(value)}"`;
            fn(extractName(value));
          })
          .catch(function (error) {
            console.log(`Failed to load font: ${error}`);
          });
      }
      fn(extractName(value));
    }
  });
};

export const _async = async (fn) => {
  const time = setTimeout(() => {
    if (isFunction(fn)) fn();
    clearTimeout(time);
  });
};

export const chunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const random = ({ min = 1, max = 10 }) => Math.floor(Math.random() * max) + min;

export const getDataBgGrad = (param) => {
  const typeParam = param.split('(')[0].trim();
  let colorsParam = [];
  let animatedParam = false;

  const count = param.split('(').length - 1;
  if (count === 1) {
    colorsParam = param.split('(')[1].split(')')[0].split(',');
    animatedParam = param.split(')')[1].trim();
  } else {
    colorsParam = param.split(' (')[1].split('))')[0] + ')';
    colorsParam = colorsParam.split('),');
    colorsParam.map((c, i) => {
      if (i !== colorsParam.length - 1) {
        c += ')';
        colorsParam[i] = c;
      }
    });

    animatedParam = param.split('))')[1].trim();
  }

  let colors = [];
  colorsParam.map((c) => {
    if (isColor(c.trim())) colors.push(chroma(c.trim()).hex());
  });

  const json = {};
  json.type = 'woxo-background';
  json.width = 1080;
  json.height = 1920;
  json.gradientType = (isGradientType(typeParam) && typeParam) || 'linear';
  json.colors = colors;
  if (animatedParam && animatedParam === 'animated') json.animation = true;

  return json;
};

export const arrayEquals = (a, b) => {
  if (a === undefined && b === undefined) return true;
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const isVideoShape = (videoShape) => {
  return !(
    !videoShape.includes('9:16') &&
    !videoShape.includes('16:9') &&
    !videoShape.includes('16:09') &&
    !videoShape.includes('1:1') &&
    !videoShape.includes('1:01') &&
    !videoShape.includes('4:05') &&
    !videoShape.includes('4:5')
  );
};

export const isTextAnimation = (animation) => {
  return !(animation.toLowerCase() !== 'default' && animation.toLowerCase() !== 'typewriter');
};

export const stringToColour = function (str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 6)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }

  const color = +`0x${colour.slice(1).replace(colour.length < 5 && /./g, '$&$&')}`;
  const r = color >> 16;
  const g = (color >> 8) & 255;
  const b = color & 255;
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return { background: colour, color: '#000' };
  }
  return { background: colour, color: '#d5d5d5' };
};

export const shiftPushArray = (arr) => {
  const element = arr.shift();
  arr.push(element);
  return element;
};
