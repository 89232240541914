import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import PlanCard from '../PlanCard';
import Dialog from '../Dialog';
import usePayment from '../../hooks/usePayment';
import { Crown } from '../Icon';
import PromoImg from '~/images/temporary_banner.jpg';
import Image from 'next/image';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from '../../helpers/notification';
import bannerData from '~/services/banner.json';

const useStyles = makeStyles((theme) => ({
  paymentsModal: {
    '& .MuiPaper-root': {
      flex: 1,
      padding: '24px 20px',
      maxWidth: 1400
    }
  },
  title: {
    fontSize: 25,
    letterSpacing: '0px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#525252',
    position: 'relative !important',
    margin: ' 0 auto',
    fontWeight: 400
  },
  notification: {
    'margin-bottom': '19px',
    marginTop: 10,
    padding: '13px',
    'font-size': '14px',
    background: '#feb429',
    position: 'relative',
    display: 'flex',
    borderRadius: 6,
    '& a': {
      color: '#525252',
      textDecoration: 'underline'
    }
  },
  subtitle: {
    fontSize: 16,
    letterSpacing: '0px',
    textAlign: 'center',
    marginTop: 5,
    lineHeight: '36px',
    '@media only screen and (max-width: 425px)': {
      fontSize: 12,
      lineHeight: '24px'
    }
  },
  modalIconWrapper: {
    display: 'flex',
    '& svg path': {
      fill: '#fff'
    }
  },
  notificationInfo: {
    margin: 'auto 10px auto',
    textAlign: 'left',
    '@media only screen and (max-width: 425px)': {
      fontSize: 14,
      lineHeight: '18px'
    }
  },
  promoImage: {
    display: 'grid',
    placeContent: 'center',
    padding: '20px',
    objectFit: 'contain',
    '&:hover': {
      '& img': {
        cursor: 'pointer'
      }
    },
    '@media only screen and (max-width: 1024px)': {
      width: '600px'
    }
  }
}));

const messages = {
  // Show in all upgrade button modals with possible selection of categories
  widget: {
    title: 'Level up your digital marketing game',
    subtitle: ''
  },
  // Only show when the user is on video page without category selector
  video: {
    title: 'Level up your content creation game',
    subtitle: ''
  }
};

const year = new Date().getFullYear();
const month = new Date().getMonth() + 1;

const PaymentsModal = ({ forwardRef, notificationText, defaultCategory, showCategorySelector }) => {
  const { handlePayment, loadingCard } = usePayment();
  const classes = useStyles();
  const modalType = showCategorySelector ? messages['widget'] : messages['video'];
  const handleCopyToClipboard = () => {
    Toast.success(
      'Code copied to clipboard. Apply on checkout page.',
      '',
      { duration: 4500, id: 'clipboard', style: { maxWidth: '450px' } },
      false
    );
  };

  return (
    <Dialog
      forwardRef={forwardRef}
      fullWidth
      className={classes.paymentsModal}
      useClose
      classNameTitle={classes.title}
      maxWidth="lg"
      title={
        <>
          {/* <Box className={classes.promoImage}>
            <CopyToClipboard text={bannerData.code} onCopy={handleCopyToClipboard}>
              <Image
                alt="promotion"
                src={PromoImg}
                layout="fixed"
                width={900}
                maxWidth={1300}
                height={110}
                priority
                objectFit="contain"
              />
            </CopyToClipboard>
          </Box> */}
          {!!notificationText && (
            <Box className={classes.notification}>
              <Box className={classes.modalIconWrapper}>
                <Crown viewBox="-2 -3 20 20" />
              </Box>
              <Box className={classes.notificationInfo}>{notificationText}</Box>
            </Box>
          )}
          <Box className={classes.title}>{modalType.title}</Box>
          <Box className={classes.subtitle}>{modalType.subtitle}</Box>
        </>
      }
    >
      <div style={{ margin: '4rem 0' }}>
        <PlanCard
          onClick={handlePayment}
          loadingCard={loadingCard}
          showRecommended={false}
          showFree={false}
          showCategorySelector={showCategorySelector}
          defaultCategory={defaultCategory}
        />
      </div>
    </Dialog>
  );
};

PaymentsModal.propTypes = {
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.any)]),
  notificationText: PropTypes.string,
  defaultCategory: PropTypes.string,
  showCategorySelector: PropTypes.bool
};

PaymentsModal.defaultProps = {
  forwardRef: () => {},
  defaultCategory: undefined,
  showCategorySelector: true
};

export default PaymentsModal;
