import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: "'Open sans', sans-serif"
  },
  spacing: 12,
  palette: {
    primary: {
      main: '#0086FF'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    },
    pages: {
      black: '#000',
      dark: '#212121',
      dark1: '#303030',
      blue: '#00A1FE',
      grey: '#7E7E7E',
      disabled: '#646464',
      green: '#80CBC4',
      white: '#fff',
      darkGreen: '#009688'
    }
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'initial'
      },
      containedPrimary: {
        backgroundColor: '#0086FF',
        '&:hover': {
          backgroundColor: '#0060BF'
        }
      },
      containedSecondary: {
        '&:hover': {
          color: '#525252',
          border: '1px solid #EEEEEE',
          backgroundColor: '#EEEEEE'
        },
        color: '#525252',
        border: '1px solid #F5F5F5',
        boxShadow: '-3px 3px 6px #00000029',
        backgroundColor: '#F5F5F5',
        borderRadius: '8px'
      },
      outlinedPrimary: {
        color: '#009688',
        borderColor: '#00968880',
        '&:hover': {
          borderColor: '#009688'
        }
      },
      root: {
        borderRadius: 0
      }
    },
    MuiIconButton: {
      colorPrimary: {
        color: '#009688',
        '&:hover': {
          color: '#00776c'
        }
      }
    },
    MuiAutocomplete: {
      listbox: {
        '&::-webkit-scrollbar': {
          width: 4
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#9a9c9d'
        }
      }
    }
  }
});

export default theme;
