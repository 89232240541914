import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { PriceItem } from './PriceItem';

const NewPriceItem = ({ plan, color, newPrice, price, offerPrice }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        marginTop: '15px'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          padding: '15px',
          minHeight: '125px',
          backgroundColor: color
        }}
      >
        <Typography
          variant="h6"
          style={{
            color: 'rgba(255,255,255,0.90',
            fontSize: '1.15rem',
            letterSpacing: '1px'
          }}
        >
          NOW AT
        </Typography>

        <Divider
          style={{ width: '100%', backgroundColor: 'rgba(255,255,255,0.95', marginTop: '8px' }}
        />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: '100%',
            height: '100%',
            margin: '0 auto',
            marginBottom: '15px',
            padding: '0px'
          }}
        >
          <Typography
            variant="h4"
            style={{
              color: 'white',
              fontSize: '3.5rem',
              position: 'relative'
            }}
          >
            {newPrice.price.toString().split('.')[0]}
            <Typography
              variant="span"
              style={{
                color: 'white',
                fontSize: '1.5rem',
                position: 'absolute',
                top: 10,
                left: -15
              }}
            >
              $
            </Typography>
            <Typography
              variant="span"
              style={{
                color: 'white',
                fontSize: '1.25rem',
                position: 'absolute',
                top: 10,
                right: -28
              }}
            >
              .
              {newPrice.price.toString().split('.')[1] === undefined
                ? 0
                : newPrice.price.toString().split('.')[1]}
              0
            </Typography>
            <Typography
              variant="subtitle"
              style={{
                width: '65.5px',
                color: 'white',
                fontSize: '0.9rem',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                bottom: -15,
                left: 0,
                right: 0
              }}
            >
              / {`MONTH`}
            </Typography>
          </Typography>
        </Box>
      </Box>
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          padding: '15px',
          minHeight: '125px'
        }}
      >
        <Typography
          variant="h6"
          style={{
            color: color,
            fontSize: '1.15rem',
            letterSpacing: '1px'
          }}
        >
          NEXT PRICE
        </Typography>

        <Divider style={{ width: '100%', backgroundColor: color, marginTop: '8px' }} />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: '100%',
            height: '100%',
            margin: '0 auto',
            marginBottom: '15px',
            padding: '0px',
            position: 'relative'
          }}
        >
          <Typography
            variant="h4"
            style={{
              color: color,
              fontSize: '2.90rem',
              position: 'relative'
            }}
          >
            {/* get price before the dot. For example in 30.00 get only 30. Its a number not string */}
            {newPrice.nextPrice.toString().split('.')[0]}
            <Typography
              variant="span"
              style={{
                color: color,
                fontSize: '1.25rem',
                position: 'absolute',
                top: 10,
                left: -12
              }}
            >
              $
            </Typography>
            <Box
              style={{
                width: '5px',
                height: '60%',
                backgroundColor: color,
                position: 'absolute',
                left: '50%',
                top: 15,
                borderRadius: '50%',
                transform: 'skew(-70deg)'
              }}
            ></Box>
            <Typography
              variant="span"
              style={{
                color: color,
                fontSize: '1.0rem',
                position: 'absolute',
                top: 10,
                right: -22
              }}
            >
              .
              {newPrice.price.toString().split('.')[1] === undefined
                ? 0
                : newPrice.price.toString().split('.')[1]}
              0
            </Typography>
            <Typography
              variant="span"
              style={{
                width: '67px',
                color: 'black',
                fontSize: '0.9rem',
                position: 'absolute',
                bottom: -15,
                right: 0,
                left: 0
              }}
            >
              {`/ MONTH`}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewPriceItem;
