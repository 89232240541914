import axios from 'axios';
import ObjectPath from 'object-path';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { isObject } from './javascript';

export const isBrowser = () => typeof window !== 'undefined';
export const setUrl = (path, value) => {
  const params = queryString.parse(window.location.search);
  if (value !== null) params[path] = value;
  else delete params[path];
  const query = queryString.stringify(params);
  const newUrl = `${window.location.origin}${window.location.pathname}${query ? `?${query}` : ''}`;
  if (window.location.href !== newUrl) window.history.replaceState(null, null, newUrl);
};
export const clientWidth = () =>
  isBrowser()
    ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    : 0;

export const Storage = class {
  path = null;

  defaultValue = null;

  constructor(path, defaultValue = '') {
    this.path = path;
    this.defaultValue = defaultValue;
  }

  save(key, value) {
    let object = null;
    if (!localStorage.getItem(this.path)) localStorage.setItem(this.path, this.defaultValue);
    try {
      object = JSON.parse(localStorage.getItem(this.path));
    } catch (e) {}
    if (object && isObject(object)) {
      ObjectPath.set(object, key, value);
      localStorage.setItem(this.path, JSON.stringify(object));
    } else localStorage.setItem(key, value);
  }

  get(key) {
    if (isBrowser()) {
      let value = localStorage.getItem(this.path);
      try {
        value = JSON.parse(value);
        if (key) return ObjectPath.get(value, key);
      } catch (e) {}
      return value;
    }
    return null;
  }
};

const STORAGE_VERSION = 3;
const versionedKeys = ['woxo-video-maker'];

export function useLocalStorage(path, defaultValue) {
  const [storage] = useState(() => new Storage(path, defaultValue));

  useEffect(() => {
    function updateStorage() {
      if (versionedKeys === null) {
        localStorage.clear();
      } else {
        versionedKeys.forEach((key) => {
          localStorage.removeItem(key);
        });
      }

      localStorage.setItem('version', STORAGE_VERSION.toString(10));
    }

    try {
      const versionValue = localStorage.getItem('version');
      if (versionValue) {
        const version = Number.parseInt(versionValue, 10);
        if (version !== STORAGE_VERSION) {
          updateStorage();
        }
      } else {
        updateStorage();
      }
    } catch (error) {
      console.error('Failed to check storage version');
    }
  }, []);

  return storage;
}

export const cancelAllRequest = () => {
  if (isBrowser() && window.Woxo && window.Woxo.Request && isObject(window.Woxo.Request.Tokens)) {
    Object.keys(window.Woxo.Request.Tokens).map((token) => {
      window.Woxo.Request.Tokens[token].cancel('Operation canceled by router.');
      return token;
    });
  }
};

export const registerRequest = (id) => {
  if (isBrowser()) {
    if (!window.Woxo) window.Woxo = {};
    if (!window.Woxo.Request) window.Woxo.Request = {};
    if (!window.Woxo.Request.Tokens) window.Woxo.Request.Tokens = {};
    window.Woxo.Request.Tokens[id] = axios.CancelToken.source();
    return window.Woxo.Request.Tokens[id].token;
  }
  return null;
};
